import React from 'react';
import GuidepostAtHomeLogoSvg from '../InlineSvg/GuidepostAtHomeLogoSvg';
import ClockIcon from '../InlineSvg/ClockIcon';
import PetIcon from '../InlineSvg/PetIcon';
import GrowthIcon from '../InlineSvg/GrowthIcon';
import WeekdayCareChart from './WeekdayCareChart';
import ReactGA from 'react-ga';

type latLngObject = {
  lat: number,
  lng: number
}

interface GoogleMapMarkerProps {
    itemKey:string
    lat:number
    lng:number
    latLng:latLngObject
    recenterMapOnClickedPinCallback: any
    showDetailsPageCallback:any
    isSelectedItem:boolean
    firstName?:string
    lastNameInitial?:string
    serviceStartDate?:string
    monthAgeOfChild?:string
    childDateOfBirth?:string
    isUnbornChild?:boolean
    petType?:string
    serviceDays?:string
    startTime?:string
    endTime?:string
    careLocationPreference?:string
    lookingForNanny:boolean
    city?:string
    state?:string
    zipCode?:string
    careFrequencyPreference?:string
    recordId:string
    isCurrentlyInMatchingState:boolean
    hasLoadedMapBackground:boolean
    wantsMondayCare:boolean
    wantsTuesdayCare:boolean
    wantsWednesdayCare:boolean
    wantsThursdayCare:boolean
    wantsFridayCare:boolean
  }


class GoogleMapMarker extends React.Component<GoogleMapMarkerProps, {}> {

  constructor(props:GoogleMapMarkerProps) {
    super(props);

    this.state = {
      showingDetailsView: false
    };
  }

  clickMarker = () => {
      this.props.recenterMapOnClickedPinCallback(this.props.itemKey, this.props.lat, this.props.lng);
  }

  clickShowDetailsButton = () => {
    this.props.showDetailsPageCallback(this.props.itemKey);
  }

  matchingLinkClickOptions = () => {
    ReactGA.event({
      category: 'Map User',
      action: 'Initiated Match Process'
    });
  }

  render() {
    const markerWrapperClass = this.props.isSelectedItem ? "o-map-embed__marker-wrapper--selected" : "o-map-embed__marker-wrapper";
    const popupBubbleClass = this.props.isSelectedItem ? "o-map-embed__popup-bubble--exposed" : "o-map-embed__popup-bubble--hidden";

    const constructedFamilyName = this.props.firstName + " " + this.props.lastNameInitial + ".";

    const inMatchingProcessInfo = <h5 className="highlight-title">
      This family is currently in the matching process with another family. Try again later!
    </h5>;

    const matchingStatusMessage = (this.props.lookingForNanny) ? "does not yet" : "does";

    const matchingStatusSubtitle = <p><i>With a care start date of <span className="a-highlight">{this.props.serviceStartDate}</span>, this family <span className="a-highlight">{matchingStatusMessage}</span> have a nanny they’re working with.</i></p>;

    const titleGroupSubtitle = (this.props.isCurrentlyInMatchingState) ? null : matchingStatusSubtitle; 

    const bornChildAgeSection = <React.Fragment>
      <h5>Current Age<br /> of Child</h5>
      <p>{this.props.monthAgeOfChild}</p></React.Fragment>;

    const unbornChildAgeSection = <React.Fragment>
      <h5>Due Date<br /> of Child</h5>
      <p>{this.props.childDateOfBirth}</p></React.Fragment>;

    const childAgeSection = (this.props.isUnbornChild) ? unbornChildAgeSection : bornChildAgeSection;
 
    const familyInfoList = <ul className="m-info-chart__icon-list">
        <li>
          <div className="m-info-chart__icon-wrapper">
            <GrowthIcon />
          </div>
          {childAgeSection}
        </li>
        <li>
          <div className="m-info-chart__icon-wrapper">
            <ClockIcon />
          </div>
          <h5>Care Schedule Preference</h5>
          <WeekdayCareChart
              wantsMondayCare={this.props.wantsMondayCare}
              wantsTuesdayCare={this.props.wantsTuesdayCare}
              wantsWednesdayCare={this.props.wantsWednesdayCare}
              wantsThursdayCare={this.props.wantsThursdayCare}
              wantsFridayCare={this.props.wantsFridayCare}
          />
        </li>
        <li>
          <div className="m-info-chart__icon-wrapper">
            <PetIcon />
          </div>
          <h5>Household<br />Pets</h5>
          <p>{this.props.petType}</p>
        </li>
    </ul>;

    const bubbleInternals = (this.props.isCurrentlyInMatchingState) ? inMatchingProcessInfo : familyInfoList; 

    const familyInfo = 
      <div className="m-info-chart">
        <hgroup className="m-info-chart__title-group">
          <h5>{constructedFamilyName}’s Family</h5>
          <h5><span style={{textTransform: 'capitalize' }}>{this.props.city}</span>, {this.props.state} {this.props.zipCode}</h5>
          {titleGroupSubtitle}
        </hgroup>
        {bubbleInternals}
      </div>;

    const matchFormLink = "https://teamck.typeform.com/to/CJPACH?recordidforfamilytheywanttomatchwith=" + this.props.recordId + "&firstnameoffamilytheywanttomatchwith=" + this.props.firstName;

    const startMatchButtonListItem = (!this.props.isCurrentlyInMatchingState) ? <li>
      <a href={matchFormLink} onClick={this.matchingLinkClickOptions}>
        <button className="a-button o-map-embed__button">
            Start Match <i className="fa fa-long-arrow-right"></i>
        </button>
      </a>
    </li> : null;

    const popupBubbleButtonsClass = (this.props.isCurrentlyInMatchingState) ? "o-map-embed__popup-bubble-buttons" : "o-map-embed__popup-bubble-buttons o-map-embed__popup-bubble-buttons--2-item";

    if (this.props.lat === 0 || this.props.lng === 0 || !this.props.recordId || !this.props.hasLoadedMapBackground)
    {
      return null;
    }

    return (
        <div className={markerWrapperClass}>
          <div onClick={this.clickMarker} className="o-map-embed__marker">
              <GuidepostAtHomeLogoSvg isDisabled={this.props.isCurrentlyInMatchingState} />
          </div>
          <div className={popupBubbleClass}>
            {familyInfo}
            <ul className={popupBubbleButtonsClass}>
              <li>
                <button onClick={this.clickShowDetailsButton} className="a-button a-button--gph-dark o-map-embed__button">
                  More Details <i className="fa fa-long-arrow-right"></i>
                </button>
              </li>
              {startMatchButtonListItem}
            </ul>
          </div>
        </div>
    );
  }
}

export default GoogleMapMarker;