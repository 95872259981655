import React from 'react';
import GuidepostAtHomeBetaLogo from './InlineSvg/GuidepostAtHomeBetaLogo';
import Headroom from 'react-headroom';
import RaggedEdgeDownExtrude from './InlineSvg/RaggedEdgeDownExtrude';

export default function Header() {
  return (
    <div className="a-hero">
        <Headroom
          pinStart={0}
          disableInlineStyles={true}
        >
        <header className="o-header o-header-nav__outer">
            <div className="o-header__logo">
              <GuidepostAtHomeBetaLogo />
            </div>
            <div className="o-header__title-area">
              <h5 className="o-header__app-title">Guidepost at Home</h5>
            </div>
        </header>
        </Headroom>
        <h3 className="a-hero__title">Guidepost at Home<br />Matching Map</h3>
        <RaggedEdgeDownExtrude />
    </div>
  );
};
