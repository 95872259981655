import React, { useEffect } from 'react';
import GoogleMap from './googleMap';
import config from '../config';
import GuidepostAtHomeLogoSvg from '../InlineSvg/GuidepostAtHomeLogoSvg';

import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton
} from "react-share";


interface FamilyMatchingProps {

}

export default function FamilyMatching (props:FamilyMatchingProps) {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])

    return (
        <div className="o-forms-container">
            <div className="o-forms-container__title-group">
                <h4 className="o-forms-container__title">
                    How to Use the Matching Map
                </h4>
                <div className="o-forms-container__subtitle">
                    <ul className="a-steps-instructions">
                        <li className="a-steps-instructions__item">
                            <div className="a-circle-number"><h5>1</h5></div>
                            <div className="a-steps-instructions__item--w-button">
                                <p><i>
                                If you haven’t already, add your own family to the map by filling out our <a href="https://teamck.typeform.com/to/wIbUu2">simple sign-up form</a>.
                                </i></p>
                                <a href="https://teamck.typeform.com/to/wIbUu2" className="a-steps-instructions__button-link">
                                    <button className="a-button">Fill Out Form</button>
                                </a>
                            </div>
                        </li>
                        <li className="a-steps-instructions__item">
                            <div className="a-circle-number"><h5>2</h5></div>
                            <p><i>
                                <span>Find families to match with by clicking the</span>
                                <span className="a-inline-svg">
                                    <GuidepostAtHomeLogoSvg isDisabled={false} />
                                </span>
                                <span>map markers near your home. Click on the markers to learn more about each family!</span>
                            </i></p>
                        </li>
                    </ul>
                  </div>
            </div>
            <div className="o-map-embed__wrapper">
                <div className="o-map-embed">
                    <GoogleMap zoom={config.mapDefaultZoom} />
                </div>
            </div>
            <div className="o-share-buttons">
                <p className="o-share-buttons__title"><i>Want more matching options near you? Share Guidepost at Home with other families!</i></p>
                <ul className="o-share-buttons__list">
                    <li>
                        <FacebookShareButton url={"https://guidepostathome.com"}>
                            <i className="fa fa-facebook"></i>
                        </FacebookShareButton>
                    </li>
                    <li>
                        <TwitterShareButton url={"https://guidepostathome.com"}>
                            <i className="fa fa-twitter"></i>
                        </TwitterShareButton>
                    </li>
                    <li>                  
                        <EmailShareButton url={"Check out https://guidepostathome.com and match with our family to co-share a nanny!"}>
                            <i className="fa fa-envelope"></i>
                        </EmailShareButton>
                    </li>
                </ul>
            </div>
            <p className="a-bottom-tag"><i>Having issues? Email us at <a href="mailto:matching@guidepostathome.com">matching@guidepostathome.com</a>.</i></p>
        </div>
    );
};