import React from 'react';

const PetIcon: React.FC = () => {
    return (
        <svg width="91px" height="80px" viewBox="0 0 91 80">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-161.000000, -47.000000)" fill="#76a4d7" fillRule="nonzero">
                    <g transform="translate(161.000000, 47.000000)">
                        <path d="M33.3333333,27.1428571 C33.9047619,27.1428571 34.3809524,27.047619 34.952381,26.952381 C37.8095238,26.3809524 40.2857143,24.4761905 41.8095238,21.5238095 C43.2380952,18.7619048 43.7142857,15.3333333 43.047619,11.9047619 C41.8095238,5.33333333 36.952381,0.666666667 31.6190476,0.666666667 C31.047619,0.666666667 30.5714286,0.761904762 30,0.857142857 C27.1428571,1.42857143 24.6666667,3.33333333 23.1428571,6.28571429 C21.7142857,9.04761905 21.2380952,12.4761905 21.9047619,15.9047619 C23.1428571,22.4761905 28.0952381,27.1428571 33.3333333,27.1428571 Z M28.3809524,8.95238095 C28.8571429,8 29.7142857,6.85714286 31.047619,6.57142857 C31.2380952,6.57142857 31.4285714,6.47619048 31.6190476,6.47619048 C33.7142857,6.47619048 36.4761905,8.95238095 37.2380952,12.952381 C37.6190476,15.1428571 37.4285714,17.2380952 36.5714286,18.8571429 C36.0952381,19.8095238 35.2380952,20.952381 33.8095238,21.2380952 C33.6190476,21.2380952 33.4285714,21.3333333 33.2380952,21.3333333 C31.1428571,21.3333333 28.3809524,18.8571429 27.6190476,14.8571429 C27.2380952,12.6666667 27.5238095,10.5714286 28.3809524,8.95238095 Z" id="Shape"></path>
                        <path d="M21.047619,26.7619048 C19.9047619,23.6190476 17.9047619,20.8571429 15.3333333,19.2380952 C13.5238095,18.0952381 11.6190476,17.5238095 9.80952381,17.5238095 C8.85714286,17.5238095 7.9047619,17.7142857 6.95238095,18 C1.52380952,19.9047619 -0.952380952,26.8571429 1.33333333,33.6190476 C2.47619048,36.8571429 4.47619048,39.5238095 7.04761905,41.1428571 C8.85714286,42.2857143 10.7619048,42.8571429 12.5714286,42.8571429 C13.5238095,42.8571429 14.4761905,42.6666667 15.4285714,42.3809524 C20.8571429,40.4761905 23.3333333,33.5238095 21.047619,26.7619048 Z M13.5238095,36.8571429 C13.2380952,36.952381 12.952381,37.047619 12.5714286,37.047619 C11.8095238,37.047619 11.047619,36.7619048 10.1904762,36.2857143 C8.66666667,35.3333333 7.52380952,33.7142857 6.85714286,31.7142857 C6.19047619,29.7142857 6.0952381,27.6190476 6.76190476,26 C7.04761905,25.2380952 7.61904762,24 8.85714286,23.5238095 C9.14285714,23.4285714 9.42857143,23.3333333 9.80952381,23.3333333 C10.5714286,23.3333333 11.3333333,23.6190476 12.1904762,24.0952381 C13.7142857,25.047619 14.8571429,26.6666667 15.5238095,28.6666667 C16.1904762,30.6666667 16.2857143,32.7619048 15.6190476,34.3809524 C15.3333333,35.1428571 14.7619048,36.3809524 13.5238095,36.8571429 Z" id="Shape"></path>
                        <path d="M56.5714286,27.047619 C57.1428571,27.1428571 57.6190476,27.2380952 58.1904762,27.2380952 C63.4285714,27.2380952 68.3809524,22.4761905 69.5238095,16 C70.1904762,12.5714286 69.7142857,9.14285714 68.2857143,6.38095238 C66.7619048,3.42857143 64.3809524,1.52380952 61.4285714,0.952380952 C60.8571429,0.857142857 60.3809524,0.761904762 59.8095238,0.761904762 C54.5714286,0.761904762 49.6190476,5.52380952 48.3809524,12 C47.7142857,15.4285714 48.1904762,18.8571429 49.6190476,21.6190476 C51.2380952,24.5714286 53.6190476,26.4761905 56.5714286,27.047619 Z M54.1904762,13.047619 C54.952381,9.04761905 57.7142857,6.57142857 59.8095238,6.57142857 C60,6.57142857 60.1904762,6.57142857 60.3809524,6.66666667 C61.8095238,6.95238095 62.6666667,8.0952381 63.1428571,9.04761905 C64,10.6666667 64.2857143,12.7619048 63.8095238,14.952381 C63.047619,18.952381 60.2857143,21.4285714 58.1904762,21.4285714 C58,21.4285714 57.8095238,21.4285714 57.6190476,21.3333333 C56.1904762,21.047619 55.3333333,19.9047619 54.8571429,18.952381 C54,17.3333333 53.8095238,15.2380952 54.1904762,13.047619 Z" id="Shape"></path>
                        <path d="M84.4761905,17.9047619 C83.5238095,17.6190476 82.5714286,17.4285714 81.6190476,17.4285714 C79.7142857,17.4285714 77.8095238,18 76.0952381,19.1428571 C73.5238095,20.8571429 71.5238095,23.5238095 70.3809524,26.6666667 C68.0952381,33.4285714 70.5714286,40.3809524 76,42.2857143 C76.952381,42.5714286 77.9047619,42.7619048 78.8571429,42.7619048 C80.7619048,42.7619048 82.6666667,42.1904762 84.3809524,41.047619 C86.952381,39.3333333 88.952381,36.6666667 90.0952381,33.5238095 C92.3809524,26.7619048 89.9047619,19.8095238 84.4761905,17.9047619 Z M84.5714286,31.6190476 C83.9047619,33.6190476 82.6666667,35.2380952 81.2380952,36.1904762 C80.4761905,36.7619048 79.6190476,36.952381 78.8571429,36.952381 C78.5714286,36.952381 78.1904762,36.8571429 77.9047619,36.7619048 C75.9047619,36.0952381 74.5714286,32.4761905 75.9047619,28.5714286 C76.5714286,26.5714286 77.8095238,24.952381 79.2380952,24 C80,23.4285714 80.8571429,23.2380952 81.6190476,23.2380952 C81.9047619,23.2380952 82.2857143,23.3333333 82.5714286,23.4285714 C83.8095238,23.8095238 84.4761905,25.1428571 84.6666667,25.9047619 C85.3333333,27.5238095 85.2380952,29.6190476 84.5714286,31.6190476 Z" id="Shape"></path>
                        <path d="M58.5714286,33.8095238 C54.8571429,30.7619048 50.2857143,29.2380952 45.7142857,29.2380952 C41.1428571,29.2380952 36.5714286,30.7619048 32.8571429,33.8095238 C23.8095238,41.1428571 13.3333333,51.7142857 13.3333333,61.5238095 C13.3333333,73.3333333 29.5238095,79.3333333 45.7142857,79.3333333 C61.9047619,79.3333333 78.0952381,73.4285714 78.0952381,61.5238095 C78.0952381,51.7142857 67.6190476,41.1428571 58.5714286,33.8095238 Z M45.7142857,73.4285714 C32.6666667,73.4285714 19.1428571,68.952381 19.1428571,61.4285714 C19.1428571,55.6190476 25.4285714,47.2380952 36.4761905,38.2857143 C39.047619,36.1904762 42.3809524,35.047619 45.7142857,35.047619 C49.047619,35.047619 52.2857143,36.1904762 54.952381,38.2857143 C66,47.2380952 72.2857143,55.6190476 72.2857143,61.4285714 C72.2857143,68.952381 58.7619048,73.4285714 45.7142857,73.4285714 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default PetIcon;