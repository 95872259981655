import React from 'react';

const ClockIcon: React.FC = () => {
    return (
        <svg width="81px" height="81px" viewBox="0 0 81 81">
            <g strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-30.000000, -46.000000)" fill="#76a4d7" fillRule="nonzero">
                    <g transform="translate(30.909910, 46.909910)">
                        <path d="M40.1498127,0 C62.0224719,0 80,17.9775281 80,40.1498127 C80,62.0224719 62.0224719,80 40.1498127,80 C17.9775281,80 0,62.0224719 0,40.1498127 C0,17.9775281 17.9775281,0 40.1498127,0 Z M35.9550562,18.2771536 C35.9550562,14.3820225 41.9475655,14.3820225 41.9475655,18.2771536 L41.9475655,39.5505618 L55.7303371,43.4456929 C59.3258427,44.6441948 57.8277154,50.3370787 53.9325843,49.4382022 L38.3520599,44.6441948 C36.8539326,44.3445693 35.9550562,43.1460674 35.9550562,41.6479401 L35.9550562,18.2771536 Z M40.1498127,5.99250936 C21.2734082,5.99250936 5.99250936,21.2734082 5.99250936,40.1498127 C5.99250936,58.7265918 21.2734082,74.0074906 40.1498127,74.0074906 C58.7265918,74.0074906 74.0074906,58.7265918 74.0074906,40.1498127 C74.0074906,21.2734082 58.7265918,5.99250936 40.1498127,5.99250936 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default ClockIcon;