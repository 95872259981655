import React from 'react';

interface WeekdayCareChartProps {
  wantsMondayCare:boolean
  wantsTuesdayCare:boolean
  wantsWednesdayCare:boolean
  wantsThursdayCare:boolean
  wantsFridayCare:boolean
}

class WeekdayCareChart extends React.Component<WeekdayCareChartProps, {}> {

    render() {

        // I know there is a more efficient way to loop! But no time
        const mondayListItemClass = (this.props.wantsMondayCare) ? "m-weekday-chart__item--highlighted" : "m-weekday-chart__item";
        const tuesdayListItemClass = (this.props.wantsTuesdayCare) ? "m-weekday-chart__item--highlighted" : "m-weekday-chart__item";
        const wednesdayListItemClass = (this.props.wantsWednesdayCare) ? "m-weekday-chart__item--highlighted" : "m-weekday-chart__item";
        const thursdayListItemClass = (this.props.wantsThursdayCare) ? "m-weekday-chart__item--highlighted" : "m-weekday-chart__item";
        const fridayListItemClass = (this.props.wantsFridayCare) ? "m-weekday-chart__item--highlighted" : "m-weekday-chart__item";    

        return (
            <ul className="m-weekday-chart">
                <li className={mondayListItemClass}>
                    <p>M</p>
                </li>
                <li className={tuesdayListItemClass}>
                    <p>Tu</p>
                </li>
                <li className={wednesdayListItemClass}>
                    <p>W</p>
                </li>
                <li className={thursdayListItemClass}>
                    <p>Th</p>
                </li>
                <li className={fridayListItemClass}>
                    <p>F</p>
                </li>
            </ul>
        );
    }
}

export default WeekdayCareChart;