import React from 'react';

const HomeIcon: React.FC = () => {
    return ( 
        <svg width="97px" height="80px" viewBox="0 0 97 80">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-415.000000, -47.000000)" fill="#76a4d7" fillRule="nonzero">
                    <g transform="translate(415.000000, 47.000000)">
                        <path d="M80.8524936,42.0384615 C79.2972688,42.0384615 78.0492488,43.2884615 78.0492488,44.8461538 L78.0492488,74.3076923 L18.3362934,74.3076923 L18.3362934,44.8461538 C18.3362934,43.2884615 17.0882734,42.0384615 15.5330485,42.0384615 C13.9778236,42.0384615 12.7298037,43.2884615 12.7298037,44.8461538 L12.7298037,77.1153846 C12.7298037,78.6730769 13.9778236,79.9230769 15.5330485,79.9230769 L80.8524936,79.9230769 C82.4077185,79.9230769 83.6557385,78.6730769 83.6557385,77.1153846 L83.6557385,44.8461538 C83.6557385,43.2884615 82.4077185,42.0384615 80.8524936,42.0384615 Z" id="Path"></path>
                        <path d="M95.0991216,35.4615385 L75.1116018,20.0769231 L75.1116018,7.67307692 C75.1116018,6.11538462 73.8635818,4.86538462 72.3083569,4.86538462 C70.7531321,4.86538462 69.5051121,6.11538462 69.5051121,7.67307692 L69.5051121,15.7692308 L49.9015984,0.673076923 C48.9031825,-0.0961538462 47.4823597,-0.0961538462 46.4839437,0.673076923 L1.28642058,35.4615385 C0.0576009216,36.4038462 -0.172802765,38.1730769 0.768012288,39.4038462 C1.70882734,40.6346154 3.4752556,40.8653846 4.70407527,39.9230769 L48.1927711,6.44230769 L91.6814669,39.9038462 C92.1998752,40.2884615 92.7950847,40.4807692 93.3902942,40.4807692 C94.2351078,40.4807692 95.060721,40.0961538 95.6175299,39.3846154 C96.5583449,38.1730769 96.3279412,36.4038462 95.0991216,35.4615385 Z" id="Path"></path>
                        <path d="M58.2921327,67.8461538 C59.8473576,67.8461538 61.0953775,66.5961538 61.0953775,65.0384615 L61.0953775,44.8461538 C61.0953775,43.2884615 59.8473576,42.0384615 58.2921327,42.0384615 L38.0934095,42.0384615 C36.5381846,42.0384615 35.2901646,43.2884615 35.2901646,44.8461538 L35.2901646,65.0384615 C35.2901646,66.5961538 36.5381846,67.8461538 38.0934095,67.8461538 L58.2921327,67.8461538 Z M40.8966543,47.6346154 L55.4888878,47.6346154 L55.4888878,62.2307692 L40.8966543,62.2307692 L40.8966543,47.6346154 Z" id="Shape"></path>
                    </g>
                </g>
            </g>
        </svg>
    );
}

export default HomeIcon;