import React from 'react';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';

import './App.css';
import Header from './Header';
//import FamilyList from './MatchingMap/FamilyList';
import FamilyMatching from './MatchingMap/FamilyMatching';
import GuidepostAtHomeBetaLogo from './InlineSvg/GuidepostAtHomeBetaLogo';

import './assets/scss/styles.scss';
import './assets/scss/font-awesome/font-awesome.scss';

ReactGA.initialize('UA-86242529-6');
ReactPixel.init('653308462169742');
ReactPixel.pageView(); 

function App() {
  return (
    <div className="root">
      <div className="overlay-wrapper">
        <div className="overlay">
          <header className="o-header o-header-nav__outer">
              <div className="o-header__logo">
                <GuidepostAtHomeBetaLogo />
              </div>
              <div className="o-header__title-area">
                <h5 className="o-header__app-title">Guidepost at Home</h5>
              </div>
          </header>
            <h3>We've moved!</h3>
            <p>We have a new and improved version of our matching map released! Please go to <a href="https://match.guidepostmontessori.com">match.guidepostmontessori.com</a> to get started or continue your matching process.</p>
            <p><b>Note:</b> when you log in for the first time, please click &ldquo;Forgot Password&rdquo; and enter your email address to reset your credentials.</p>
            <a href="https://match.guidepostmontessori.com" className="a-steps-instructions__button-link">
                <button className="a-button">Visit Our New Location</button>
            </a>
        </div>
      </div>
      <Header />
      <FamilyMatching />
      {/* <FamilyList /> */}
    </div>
  );
}

export default App;
