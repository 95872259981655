import React from 'react';

const RaggedEdgeDownExtrude: React.FC = () => {
    return (
        <svg className="rag-line rag-line__extrude rag-line__bottom rag-line__bottom-extrude" viewBox="0 0 894.42 50.51">
            <g>
            <path className="rag-line--paper" d="M-7.64,34.27V.82H875.8l-1.44,37.72A1351.2,1351.2,0,0,0,739,31.68c-71.49.21-571.34,6.87-625.17,6.87" transform="translate(18.62 -0.82)"/>
            <path className="rag-line--dots" d="M873.67,49.59a1072.63,1072.63,0,0,0-131.55-6.77c-27.2.32-210.3,1.87-387.11,4.22-76.29,1-151.41-.28-212.33,2.59C123.69,50.52-17,40-17,40" transform="translate(18.62 -0.82)" />
            </g>
        </svg>
    );
}

export default RaggedEdgeDownExtrude;