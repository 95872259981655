import React from 'react';
import GoogleMapReact from 'google-map-react';
import GoogleMapMarker from './googleMapMarker';
import FamilyDetailsPage from './FamilyDetailsPage';
import config from '../config';
import { load } from '../helpers/spreadsheet';
import { FamilyArrayTypes } from './interfaces';

declare global {
    interface Window { gapi: any; }
}

window.gapi = window.gapi || {};

interface GoogleMapProps {
    zoom: number
}

interface GoogleMapState {
  centerLatitude: number,
  centerLongitude: number,
  isDisplayingDetailsPage: boolean,
  isSelectedKey: string, //TODO: make number,
  isSelectedDetailsKey: string //TODO: make number,
  families: Array<FamilyArrayTypes>,
  loaded: boolean,
  mapApiLoaded: boolean,
  error: any | null
}

class GoogleMap extends React.Component<GoogleMapProps, GoogleMapState> {

  constructor(props:GoogleMapProps) {
    super(props);

    this.state = {
      centerLatitude: config.mapCenteringCoordLat,
      centerLongitude: config.mapCenteringCoordLong,
      isSelectedKey: "",
      isSelectedDetailsKey: "", //TODO: make number
      isDisplayingDetailsPage: false,
      families: [],
      error: null,
      mapApiLoaded: false,
      loaded: false
    };

    this.recenterMapOnClickedPin = this.recenterMapOnClickedPin.bind(this);

    this.toggleDetailsPage = this.toggleDetailsPage.bind(this);

    this.hideDetailsPage = this.hideDetailsPage.bind(this);

    this.resetSelectedItemToNull = this.resetSelectedItemToNull.bind(this);

    this.onLoad = this.onLoad.bind(this);

    this.initClient = this.initClient.bind(this);
    
    this.createMapOptions = this.createMapOptions.bind(this);

    this.onGoogleTilesLoaded = this.onGoogleTilesLoaded.bind(this);
  }

  createMapOptions(maps:any) {
    return {
      mapTypeControl: false,
      clickableIcons: false,
      fullscreenControl: false
    }
  }

  recenterMapOnClickedPin(selectedItemKey: string, pinLatitude: number, pinLongitude:number) {
    this.setState({
      centerLatitude: pinLatitude,
      centerLongitude: pinLongitude,
      isSelectedKey: selectedItemKey
    });
  }

  toggleDetailsPage(selectedItemDetailsKey: string) {
    this.setState(prevState => ({
      isDisplayingDetailsPage: !prevState.isDisplayingDetailsPage,
      isSelectedDetailsKey: selectedItemDetailsKey
    }));
  }

  hideDetailsPage() {
    this.setState({
      isDisplayingDetailsPage: false,
      isSelectedDetailsKey: ""
    });
  }

  resetSelectedItemToNull() {
    this.setState({
      isSelectedKey: ""
    });
  }

  onGoogleTilesLoaded() {
    this.setState({ mapApiLoaded : true })
  }

  onLoad = (data:any, error:any) => {
    if (data) {
      const families = data.families;
      this.setState({ families });
      this.setState({ loaded : true });
    } else {
      this.setState({ error });
    }
  };

  initClient = () => {
    // 2. Initialize the JavaScript client library.
    window.gapi.client
      .init({
        apiKey: config.apiKey
      })
      .then(() => {
      // 3. Initialize and make the API request.
      //load(this.onLoad);      
      this.setState({ loaded : true });
    });
  };

  componentDidMount() {
  // 1. Load the JavaScript client library.
      window.gapi.load("client", this.initClient);
  }

  render() {
    const { families, error, loaded } = this.state;
        
    if (!loaded) {
        return <div className="o-map-embed__loading-message">
          <h5>Loading Our Map&hellip;</h5>
        </div>;
    }
    
    if (error) {
        return <div>{this.state.error.message}</div>;
    }

    let newMapMarkerElements = families.map((family, i) => (
        <GoogleMapMarker
          key={i}
          hasLoadedMapBackground={this.state.mapApiLoaded}
          itemKey={(family.recordId !== undefined ? family.recordId : i.toString())}
          latLng={family.latLng}
          lat={family.latLng.lat}
          lng={family.latLng.lng}
          firstName={family.firstName}
          lastNameInitial={family.lastNameInitial}
          serviceStartDate={family.serviceStartDate}
          monthAgeOfChild={family.monthAgeOfChild}
          childDateOfBirth={family.childDateOfBirth}
          isUnbornChild={family.isUnbornChild}
          petType={family.petType}
          serviceDays={family.serviceDays}
          startTime={family.startTime}
          endTime={family.endTime}
          careLocationPreference={family.careLocationPreference}
          lookingForNanny={family.lookingForNanny}
          city={family.city}
          state={family.state}
          zipCode={family.zipCode}
          careFrequencyPreference={family.careFrequencyPreference}
          recordId={(family.recordId !== undefined ? family.recordId : "")}
          isCurrentlyInMatchingState={family.isCurrentlyInMatchingState}
          recenterMapOnClickedPinCallback={this.recenterMapOnClickedPin}
          isSelectedItem={family.recordId === this.state.isSelectedKey}
          showDetailsPageCallback={this.toggleDetailsPage}
          wantsMondayCare={family.wantsMondayCare}
          wantsTuesdayCare={family.wantsTuesdayCare}
          wantsWednesdayCare={family.wantsWednesdayCare}
          wantsThursdayCare={family.wantsThursdayCare}
          wantsFridayCare={family.wantsFridayCare}
      />
    ));

    let familyChartElements = families.map((family, i) => (
      <FamilyDetailsPage
        key={i}
        hasLoadedMapBackground={this.state.mapApiLoaded}
        lat={family.latLng.lat}
        lng={family.latLng.lng}
        firstName={family.firstName}
        lastNameInitial={family.lastNameInitial}
        serviceStartDate={family.serviceStartDate}
        monthAgeOfChild={family.monthAgeOfChild}
        childDateOfBirth={family.childDateOfBirth}
        isUnbornChild={family.isUnbornChild}
        petType={family.petType}
        serviceDays={family.serviceDays}
        startTime={family.startTime}
        endTime={family.endTime}
        careLocationPreference={family.careLocationPreference}
        lookingForNanny={family.lookingForNanny}
        city={family.city}
        state={family.state}
        zipCode={family.zipCode}
        careFrequencyPreference={family.careFrequencyPreference}
        recordId={(family.recordId !== undefined? family.recordId : "")}
        isCurrentlyInMatchingState={family.isCurrentlyInMatchingState}
        isSelectedItem={family.recordId === this.state.isSelectedDetailsKey}
        hideSelfCallback={this.hideDetailsPage}
        bio={family.bio}
        notes={family.notes}
        wantsMondayCare={family.wantsMondayCare}
        wantsTuesdayCare={family.wantsTuesdayCare}
        wantsWednesdayCare={family.wantsWednesdayCare}
        wantsThursdayCare={family.wantsThursdayCare}
        wantsFridayCare={family.wantsFridayCare}
    />
    ));

    return (
      <div style={{ position: 'relative', overflow: 'hidden', height: '100%', width: '100%' }}>
          <GoogleMapReact
            onClick={this.resetSelectedItemToNull}
            bootstrapURLKeys={{ key: config.apiKey }}
            center={{
              lat: this.state.centerLatitude,
              lng: this.state.centerLongitude
            }}
            options={
              this.createMapOptions(Map)
            }
            onTilesLoaded={this.onGoogleTilesLoaded}
            yesIWantToUseGoogleMapApiInternals
            defaultZoom={this.props.zoom}>
          </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMap;