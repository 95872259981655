import React from 'react';
import GoogleMapReact from 'google-map-react';
import GuidepostAtHomeLogoSvg from '../InlineSvg/GuidepostAtHomeLogoSvg';
import config from '../config';
import ClockIcon from '../InlineSvg/ClockIcon';
import PetIcon from '../InlineSvg/PetIcon';
import GrowthIcon from '../InlineSvg/GrowthIcon';
import HomeIcon from '../InlineSvg/HomeIcon';
import WeekdayCareChart from './WeekdayCareChart';
import ReactGA from 'react-ga';

interface FamilyDetailsPageProps {
  lat:number
  lng:number
  isSelectedItem:boolean
  hasLoadedMapBackground:boolean
  hideSelfCallback: any
  firstName?: string
  lastNameInitial?: string
  serviceStartDate?: string
  monthAgeOfChild?: string
  childDateOfBirth?:string
  isUnbornChild?:boolean
  petType?: string
  serviceDays?: string
  startTime?: string
  endTime?:string
  careLocationPreference?:string
  lookingForNanny:boolean
  city?:string
  state?:string
  zipCode?:string
  careFrequencyPreference?:string
  recordId:string
  isCurrentlyInMatchingState:boolean
  bio?:string
  notes?:string
  wantsMondayCare:boolean
  wantsTuesdayCare:boolean
  wantsWednesdayCare:boolean
  wantsThursdayCare:boolean
  wantsFridayCare:boolean
}

class FamilyDetailsPage extends React.Component<FamilyDetailsPageProps, {}> {

  constructor(props:FamilyDetailsPageProps) {
    super(props);

    this.createMapOptions = this.createMapOptions.bind(this);
  }

  createMapOptions(maps:any) {
    return {
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      draggable: false
    }
  }

  matchingLinkClickOptions = () => {
    ReactGA.event({
      category: 'Map User',
      action: 'Initiated Match Process'
    });
  }

  render() {
    
    const constructedFamilyName = this.props.firstName + " " + this.props.lastNameInitial + ".";

    const detailsPageClass = this.props.isSelectedItem ? "m-details-page m-details-page--exposed" : "m-details-page";

    const inMatchingProcessInfo = <p><i>This family is currently in the matching process with another family. Try again later!</i></p>;

    const matchingStatusMessage = (this.props.lookingForNanny) ? "does not yet" : "does";

    const matchingStatusSubtitle = <p className="m-info-chart__subtitle"><i>With a care start date of <span className="a-highlight">{this.props.serviceStartDate}</span>, this family <span className="a-highlight">{matchingStatusMessage}</span> have a nanny they are already working with.</i></p>;

    const titleGroupSubtitle = (this.props.isCurrentlyInMatchingState) ? inMatchingProcessInfo : matchingStatusSubtitle; 

    if (this.props.lat === 0 || this.props.lng === 0)
    {
      return null;
    }

    const notesSection = (this.props.notes !== undefined && this.props.notes) ? <div className="m-details-page__text-item">
        <h5>Other Nanny Preference Notes</h5>
        <p>
          {this.props.notes}
        </p>
    </div> : null;

    const bioSection = (this.props.bio !== undefined && this.props.bio) ? <div className="m-details-page__text-item">
      <h5>Family Biography</h5>
      <p>
        {this.props.bio}
      </p>
    </div> : null;

    const matchFormLink = "https://teamck.typeform.com/to/CJPACH?recordidforfamilytheywanttomatchwith=" + this.props.recordId + "&firstnameoffamilytheywanttomatchwith=" + this.props.firstName;

    const matchingButtonSection = (!this.props.isCurrentlyInMatchingState) ? <a onClick={this.matchingLinkClickOptions} className="m-details-page__action-button-wrapper" href={matchFormLink}>
      <button className="a-button m-details-page__action-button" onClick={this.props.hideSelfCallback}> Start Matching Process</button>
    </a> : null; 

    const bornChildAgeSection = <React.Fragment>
    <h5>Current Age<br /> of Child</h5>
    <p>{this.props.monthAgeOfChild}</p></React.Fragment>;

    const unbornChildAgeSection = <React.Fragment>
    <h5>Due Date<br /> of Child</h5>
    <p>{this.props.childDateOfBirth}</p></React.Fragment>;

    const childAgeSection = (this.props.isUnbornChild) ? unbornChildAgeSection : bornChildAgeSection;

    if (this.props.lat === 0 || this.props.lng === 0 || !this.props.recordId || !this.props.hasLoadedMapBackground || !this.props.isSelectedItem)
    {
      return null;
    }

    return (
      <div className={detailsPageClass}>
        <button className="a-button a-button--gph-dark m-details-page__close-button" onClick={this.props.hideSelfCallback}><i className="fa fa-times"></i> Close</button>
        <div className="m-details-page__inner">
          <div className="m-info-chart m-details-page__info-chart">
            <div className="m-details-page__map-circle">
              <GoogleMapReact
                  bootstrapURLKeys={{ key: config.apiKey }}
                  center={{
                    lat: this.props.lat,
                    lng: this.props.lng
                  }}
                  defaultZoom={13}
                  yesIWantToUseGoogleMapApiInternals
                  options={
                    this.createMapOptions(Map)
                  }>
                  <div className="o-map-embed__marker-wrapper">
                    <div className="o-map-embed__marker">
                        <GuidepostAtHomeLogoSvg isDisabled={false} />
                    </div>
                  </div>
              </GoogleMapReact>
            </div>
            <hgroup className="m-info-chart__title-group">
              <h5>{constructedFamilyName}’s Family</h5>
              <h5>{this.props.city}, {this.props.state} {this.props.zipCode}</h5>
              {titleGroupSubtitle}
            </hgroup>
            <ul className="m-info-chart__icon-list m-details-page__icon-list">
              <li>
                <div className="m-info-chart__icon-wrapper">
                  <GrowthIcon />
                </div>
                {childAgeSection}
              </li>
              <li>
                <div className="m-info-chart__icon-wrapper">
                  <PetIcon />
                </div>
                <h5>Household<br />Pets</h5>
                <p>{this.props.petType}</p>
              </li>
              <li>
                <div className="m-info-chart__icon-wrapper">
                  <ClockIcon />
                </div>
                <h5>Care Schedule<br />Preference</h5>
                <WeekdayCareChart
                  wantsMondayCare={this.props.wantsMondayCare}
                  wantsTuesdayCare={this.props.wantsTuesdayCare}
                  wantsWednesdayCare={this.props.wantsWednesdayCare}
                  wantsThursdayCare={this.props.wantsThursdayCare}
                  wantsFridayCare={this.props.wantsFridayCare}
                />
                <p><i className="fa fa-clock-o"></i> {this.props.startTime}&ndash;{this.props.endTime}</p>
              </li>
              <li>
                <div className="m-info-chart__icon-wrapper">
                  <HomeIcon />
                </div>
                <h5>Care Location<br />Preference</h5>
                <p>{this.props.careLocationPreference}</p>
              </li>
            </ul>
          </div>
          <div className="m-details-page__text-items">
            {bioSection}
            {notesSection}
          </div>
          {matchingButtonSection}
        </div>
      </div>
    );
  }
}

export default FamilyDetailsPage;